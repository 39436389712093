import { product, apiGet, _err } from "../../services/api";
import * as _ from "lodash";
import Swal from "sweetalert2";
export default {
  name: "Footer",
  data() {
    return {
      productList: null,
      work_samples: [
        { name_work: this.$t("footer.subFootprint.officeBuildings") },
        { name_work: this.$t("footer.subFootprint.home") },
      ],
      map_link:[
        { map_name: this.$t("footer.subFootprint.map") },
      ],
      technical_data: [
        { name: this.$t("footer.subTechnicalSupport.glassConfigurator") },
        { name: this.$t("footer.subTechnicalSupport.download") },
        { name: this.$t("footer.subTechnicalSupport.lawAndRuleOfGlass") },
      ],
      contact_us: [
        { name: this.$t("footer.subContactUs.sendMessage") },
        { name: this.$t("footer.subContactUs.faq") },
      ],
      form: {
        email: "",
      },
    };
  },
  methods: {
    gotoPage(url){
      location.href = url;
    },
    async sendSubscribe() {
      if (this.form.email == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "กรุณากรอกข้อมูล",
          confirmButtonText: "ปิด",
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      } else {
        let obj = {
          request: this.form,
        };
        await this.$axios
          .post("/api/send/subscribe", obj)
          .then((response) => {
            if (response.data.code == 200) {
              Swal.fire({
                icon: "success",
                title: "ขอบคุณสำหรับการติดตาม",
                html: "รอรับข่าวสาร และไอเดียใหม่ๆ <br>จาก เอจีซี แฟลทกลาส(ประเทศไทย)",
                confirmButtonText: "ปิด",
                // footer: '<a href="">Why do I have this issue?</a>'
              });
              this.form = {
                email: "",
              };
            }
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              confirmButtonText: "ปิด",
              // footer: '<a href="">Why do I have this issue?</a>'
            });
          });
      }
    },
    // eslint-disable-next-line
    gotoDetail(vals) {
      // this.$router.push({ name: 'product-detail' })
      // this.$router.push('./product-detail/'+vals)
      console.log(location, this.$router.currentRoute);
      // this.$router.push({ path: `/product-detail/${vals}`})
      // this.$router.push({name: this.$router.currentRoute.name, params: {id:vals}})
      // location.reload()
      location.href = "../../product-detail/" + vals;
    },
  },
  async mounted() {
    // this.productList = await apiGet(product, {
    //   page: 1,
    //   limit: 100000000,
    //   sort: "ASC",
    // });
    // if (this.productList.length) {
    //   this.productList = _.uniqBy(this.productList, "product_category_id");
    // }
    // if (!this.productList.length) {
    //   _err("No data");
    // }
  },
};
