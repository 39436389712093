import $ from "jquery";
import ToolbarResponsive from "@/components/toolbarResponsive/toolbar";
export default {
    name: "Header",
    data() {
        return {
            windowTop: 0,
            window_offset: 1,
            currentPage: window.location.pathname,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    },
    methods: {
        gotoPage(url){
            location.href = url;
        },
        onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            if (this.windowTop > this.window_offset) {
                $('#header').addClass('swap-color');
            } else if (this.windowTop < this.window_offset) {
                $('#header').addClass('swap-color');
            }
        },
        show(value) {
          document.getElementById(value).classList.add("show");
        },
        hide(value) {
          document.getElementById(value).classList.remove("show");
        }
    },
    components: {
        ToolbarResponsive,
      },
};
